import React from 'react'

import {
  GenericEditableTable,
  CommonGenericEditableTableRowOptions,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { API } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import {
  onboardingCandidatesV2CountryColumn,
  onboardingCandidatesV2EmailColumn,
  onboardingCandidatesV2JobTitleColumn,
  onboardingCandidatesV2NameColumn,
  onboardingCandidatesV2RecruiterColumn,
  onboardingCandidatesV2RoleColumn,
  onboardingCandidatesV2SeniorityColumn,
} from '@src/constants/columns/importCandidatesV2'
import { CandidateSimpleInterface } from '@src/interfaces/importCandidates'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<CandidateSimpleInterface>> => ({
  cells: [
    {
      ...onboardingCandidatesV2NameColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingCandidatesV2EmailColumn(options.onChange),
      width: 120,
    },
    {
      ...onboardingCandidatesV2RoleColumn(options.onChange),
      width: 150,
    },
    {
      ...onboardingCandidatesV2CountryColumn(options.onChange),
      width: 150,
    },
    {
      ...onboardingCandidatesV2SeniorityColumn(options.onChange),
      width: 150,
    },
    {
      ...onboardingCandidatesV2RecruiterColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingCandidatesV2JobTitleColumn(options.onChange),
      width: 200,
    },
  ],
})

export const CandidatesImport = () => {
  return (
    <GenericEditableTable
      apiEndpoint={API.CANDIDATES_SIMPLE}
      apiVersion="v2"
      tableName={TableNames.ImportCandidatesV2}
      row={row}
      entity="candidate"
      variant="existingEntities"
      hiddenColumns={{ action: true }}
      tableActions={() => (
        <MoreBar>
          <MoreBar.Action
            use={InternalLink}
            to={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.NEW}
            useIcon="Plus"
          >
            Add candidate
          </MoreBar.Action>
          <MoreBar.Action
            use={InternalLink}
            to={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.UPLOAD.IMPORT}
            useIcon="Upload"
          >
            Import candidates
          </MoreBar.Action>
        </MoreBar>
      )}
    />
  )
}
