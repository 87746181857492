import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { jobsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'
import { Route, Switch } from 'react-router-dom'
import { JobsIntro } from '@src/pages/OnboardingChecklistV2/Jobs/JobsIntro'
import { JobsImport } from '@src/pages/OnboardingChecklistV2/Jobs/JobsImport'
import { JobsFlow } from '@src/pages/OnboardingChecklistV2/Jobs/JobsFlow'
import { NewRequisitionForm } from '@src/pages/OnboardingChecklistV2/Jobs/NewRequisitionForm'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.INTRO,
    canView: [PermissionTypes.ViewJobPostingPreferences],
    component: JobsIntro,
  },
  {
    title: 'Import',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.IMPORT,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.IMPORT,
    canView: [PermissionTypes.ViewJobPostingPreferences],
    component: JobsImport,
    isWide: true,
  },
]

export const Jobs = () => {
  return (
    <Switch>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.UPLOAD.ANY}>
        <JobsFlow />
      </Route>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.NEW}>
        <NewRequisitionForm />
      </Route>
      <Route>
        <OnboardingChecklistContent config={jobsConfig} routes={routes} />
      </Route>
    </Switch>
  )
}
