import React from 'react'
import { PageBody } from '@components/Page/PageBody'
import { InputGroup } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { OptionInterface } from '@src/interfaces/selectors'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { NewCandidateInterface } from '@src/interfaces/newCandidate'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { getNewCandidateRequests } from '@src/api/newCandidate'
import Form from '@src/features/Form/Form'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useGetSelectors } from '@src/api/selectors'

const NewCandidateFormComponent = () => {
  const user = useSelector(selectUser)
  const { values } = useLapeContext<NewCandidateInterface>()
  const { data: jobPostings } = useGetSelectors(selectorKeys.job_postings)

  if (user && !values.created_by) {
    values.created_by = { id: user.id, name: user.full_name }
  }

  return (
    <>
      <PageHeader
        title="Add candidate"
        backUrl={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.IMPORT}
      />
      <PageBody>
        <InputGroup>
          <LapeNewInput name="full_name" label="Full name" required />
          <LapeNewInput name="email" label="Email" required />
          <LapeNewInput
            name="links"
            value={values.links?.[0]}
            label="LinkedIn url"
            onChange={e => {
              if (e.currentTarget.value) {
                values.links = [e.currentTarget.value]
              } else {
                delete values.links
              }
            }}
          />
          <LapeRadioSelectInput<OptionInterface>
            name="posting_id"
            label="Job title"
            selector={selectorKeys.job_postings}
            value={jobPostings?.find(
              item => String(item.id) === String(values.posting_id),
            )}
            useQuery
            onChange={val => {
              if (val) {
                values.posting_id = String(val.id)
              }
            }}
            optional
          />
          <LapeRadioSelectInput
            name="specialisation"
            label="Role"
            selector={selectorKeys.specialisations}
            optional
          />
          <LapeRadioSelectInput<OptionInterface>
            name="country"
            label="Current country"
            selector={selectorKeys.countries}
          />
          <LapeRadioSelectInput
            name="seniority"
            label="Seniority"
            selector={selectorKeys.seniority}
            optional
          />
          <LapeRadioSelectInput
            name="recruiter"
            label="Recruiter"
            selector={selectorKeys.employee}
          />
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup<NewCandidateInterface>
          successText="New candidate added successfully"
          useValidator
          afterSubmitUrl={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.IMPORT}
        />
      </PageActions>
    </>
  )
}

export const NewCandidateForm = connect(() => (
  <Form api={getNewCandidateRequests()}>
    <NewCandidateFormComponent />
  </Form>
))
