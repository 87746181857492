import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'

import {
  CommonGenericEditableTableRowOptions,
  GenericEditableTable,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { API } from '@src/constants/api'
import {
  onboardingRoleHeadcountColumn,
  onboardingRoleNameColumn,
  onboardingRoleOwnerColumn,
  onboardingRoleSeniorityColumn,
} from '@src/constants/columns/onboardingRoles'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { CreateRolePopup } from '@src/features/EditableEmployeesTable'
import {
  BulkUpdateRolePopup,
  ChangeSenioritiesPopup,
  DeleteRolesConfirmationPopup,
  MergeRolesPopup,
} from './components'

const row =
  (
    onSenioritiesClick: (
      data: SpecialisationInterface,
      refreshTabeState: () => void,
    ) => void,
  ) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<SpecialisationInterface>> => ({
    cells: [
      {
        ...onboardingRoleNameColumn(options.onChange),
        width: 200,
      },
      {
        ...onboardingRoleHeadcountColumn(options.onChange),
        width: 100,
      },
      {
        ...onboardingRoleSeniorityColumn(options.onChange, data =>
          onSenioritiesClick(data, options.refreshTableState),
        ),
        width: 200,
      },
      {
        ...onboardingRoleOwnerColumn(options.onChange),
        width: 200,
      },
    ],
  })

type AddRolePopupState = {
  type: 'add-role'
  refreshTable: () => void
}
type BulkUpdateFieldPopupState = {
  type: 'bulk-update-field'
  field?: 'owner'
  items?: number[]
  refreshTable: () => void
}
type BulkDeletePopupState = {
  type: 'bulk-delete'
  items?: number[]
  refreshTable: () => void
}
type MergeRolesPopupState = {
  type: 'merge-roles'
  items?: number[]
  refreshTable: () => void
}
type ChangeSenioritiesPopupState = {
  type: 'change-seniorities'
  data: SpecialisationInterface
  refreshTable: () => void
}
type PopupState =
  | null
  | AddRolePopupState
  | BulkUpdateFieldPopupState
  | BulkDeletePopupState
  | MergeRolesPopupState
  | ChangeSenioritiesPopupState

export const ReviewRoles = () => {
  const [popupState, setPopupState] = useState<PopupState>(null)

  const onSenioritiesClick = (
    data: SpecialisationInterface,
    refreshTable: () => void,
  ) => {
    setPopupState({ type: 'change-seniorities', data, refreshTable })
  }

  return (
    <>
      <GenericEditableTable
        apiEndpoint={API.SPECIALISATIONS}
        tableName={TableNames.RolesOnboarding}
        row={row(onSenioritiesClick)}
        entity="role"
        variant="existingEntities"
        filterByInitial={[
          {
            filters: [
              { id: 'pending', name: 'pending' },
              { id: 'approved', name: 'approved' },
              { id: 'draft', name: 'draft' },
            ],
            columnName: 'status',
            nonResettable: true,
          },
        ]}
        sortByInitial={[{ sortBy: 'name', nonResettable: true }]}
        tableActions={props => {
          const selectedItems = props.getSelectedItems()
          const disableActions = selectedItems?.length < 1
          const disableMerge = selectedItems?.length < 2

          return (
            <MoreBar>
              <MoreBar.Action
                useIcon="Plus"
                onClick={() => {
                  setPopupState({
                    type: 'add-role',
                    refreshTable: props.refreshTableState,
                  })
                }}
              >
                Add role
              </MoreBar.Action>
              <MoreBar.Action
                useIcon="Materials"
                onClick={() => {
                  setPopupState({
                    type: 'merge-roles',
                    items: props.getSelectedItems(),
                    refreshTable: props.refreshTableState,
                  })
                }}
                disabled={disableMerge}
              >
                Merge roles
              </MoreBar.Action>
              <MoreBar.Action
                useIcon="ArrowRightLeft"
                onClick={() => {
                  setPopupState({
                    type: 'bulk-update-field',
                    field: 'owner',
                    items: props.getSelectedItems(),
                    refreshTable: props.refreshTableState,
                  })
                }}
                disabled={disableActions}
              >
                Change owner
              </MoreBar.Action>
              <MoreBar.Action
                onClick={() => {
                  setPopupState({
                    type: 'bulk-delete',
                    items: props.getSelectedItems(),
                    refreshTable: props.refreshTableState,
                  })
                }}
                variant="negative"
                useIcon="Delete"
                disabled={disableActions}
              >
                Delete
              </MoreBar.Action>
            </MoreBar>
          )
        }}
        deleteConfirmation={props =>
          props.id ? (
            <DeleteRolesConfirmationPopup
              open={props.open}
              onClose={props.onClose}
              rolesToDelete={[props.id]}
              onSuccess={props?.onSuccess}
            />
          ) : null
        }
      />

      {popupState?.type === 'add-role' && (
        <CreateRolePopup
          open
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          onClose={() => setPopupState(null)}
        />
      )}

      {popupState?.type === 'merge-roles' && (
        <MergeRolesPopup
          open
          onClose={() => setPopupState(null)}
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          unitsToMerge={popupState.items}
        />
      )}

      {popupState?.type === 'bulk-update-field' && (
        <BulkUpdateRolePopup
          open
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          onClose={() => setPopupState(null)}
          entity={popupState.field}
          selectedRoles={popupState.items}
        />
      )}

      {popupState?.type === 'bulk-delete' && (
        <DeleteRolesConfirmationPopup
          open
          onClose={() => setPopupState(null)}
          rolesToDelete={popupState.items}
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
        />
      )}

      {popupState?.type === 'change-seniorities' && (
        <ChangeSenioritiesPopup
          open
          data={popupState.data}
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          onClose={() => setPopupState(null)}
        />
      )}
    </>
  )
}
