import React from 'react'

import {
  GenericEditableTable,
  CommonGenericEditableTableRowOptions,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { API } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import {
  onboardingJobsV2DescriptionColumn,
  onboardingJobsV2LocationsColumn,
  onboardingJobsV2RoleColumn,
  onboardingJobsV2TitleColumn,
} from '@src/constants/columns/importJobsV2'
import { RequisitionPostingSimpleInterface } from '@src/interfaces/requisitions'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<RequisitionPostingSimpleInterface>> => ({
  cells: [
    {
      ...onboardingJobsV2TitleColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingJobsV2RoleColumn(options.onChange),
      width: 150,
    },
    {
      ...onboardingJobsV2DescriptionColumn(options.onChange),
      width: 150,
    },
    {
      ...onboardingJobsV2LocationsColumn(options.onChange),
      width: 150,
    },
  ],
})

export const JobsImport = () => {
  return (
    <GenericEditableTable
      apiEndpoint={API.REQUISITION_POSTING_SIMPLE}
      apiVersion="v2"
      tableName={TableNames.ImportJobsV2}
      row={row}
      entity="job"
      variant="existingEntities"
      hiddenColumns={{ action: true }}
      tableActions={() => (
        <MoreBar>
          <MoreBar.Action
            use={InternalLink}
            to={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.NEW}
            useIcon="Plus"
          >
            Add requisition
          </MoreBar.Action>
          <MoreBar.Action
            use={InternalLink}
            to={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.UPLOAD.IMPORT}
            useIcon="Upload"
          >
            Import requisitions
          </MoreBar.Action>
        </MoreBar>
      )}
    />
  )
}
