import React, { useState } from 'react'

import { CellTypes } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import {
  CellWithError,
  EmployeeSelectCell,
  GenericEditableTableColumn,
  GenericEditableTableOnChange,
  MultiSelectInputCell,
  RadioSelectInputCell,
  SeniorityRangeEditPopup,
  SeniorityValue,
  TextCell,
} from '@src/features/GenericEditableTable/components'
import { ImportJobsInterface } from '@src/interfaces/importJobs'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { useGetSelectors } from '@src/api/selectors'
import { Box, Button, Header, Popup, Skeleton, Text } from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import HTMLEditor from '@components/HTMLEditor/HTMLEditor'
import DOMPurify from 'dompurify'
import { OptionInterface } from '@src/interfaces/selectors'
import { RequisitionPostingSimpleInterface } from '@src/interfaces/requisitions'
import { JobDescriptionSections } from '@src/pages/Forms/JobPosting/utils'
import { TableCellInputType } from '@components/Inputs/TableCellInput/TableCellInput'
import { getRequisitionsSelectorOptions } from '@src/api/requisitions'

type ImportJobColumn = GenericEditableTableColumn<ImportJobsInterface>

type OnboardingJobColumn = GenericEditableTableColumn<RequisitionPostingSimpleInterface>

export const importJobsV2TitleColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Title',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="requisition_title" />
  ),
})

export const importJobsV2TeamColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Team',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="team"
      selector={selectorKeys.team}
      useNameField
    />
  ),
})

export const importJobsV2RoleColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'role',
  dataPoint: 'role',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.specialisations,
  title: 'Role',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="specialisation"
      fieldName="role"
      selector={selectorKeys.specialisations}
      useNameField
    />
  ),
})

export const importJobsV2HeadcountColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Headcount',
  insert: ({ data }) => (
    <TextCell
      type={TableCellInputType.int}
      data={data}
      onChange={onChange}
      field="headcount"
    />
  ),
})

const SeniorityColumn = ({
  data,
  onChange,
}: {
  data: ImportInterface<ImportJobsInterface>
  onChange: GenericEditableTableOnChange
}) => {
  const [popupOpen, setPopupOpen] = useState(false)
  const [seniorityMin, setSeniorityMin] = useState<OptionInterface | null>()
  const [seniorityMax, setSeniorityMax] = useState<OptionInterface | null>()

  const onSubmit = () => {
    onChange(data.id, seniorityMin?.name, 'seniority_min')
    onChange(data.id, seniorityMax?.name, 'seniority_max')
    setPopupOpen(false)
  }

  return (
    <>
      <Box style={{ cursor: 'pointer' }} onClick={() => setPopupOpen(true)}>
        <CellWithError data={data} field={['seniority_min', 'seniority_max']}>
          <SeniorityValue
            minSeniority={data.data.seniority_min}
            maxSeniority={data.data.seniority_max}
            error={Boolean(data.errors.seniority_min || data.errors.seniority_max)}
          />
        </CellWithError>
      </Box>

      <SeniorityRangeEditPopup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        seniorityMin={seniorityMin}
        seniorityMax={seniorityMax}
        setSeniorityMin={setSeniorityMin}
        setSeniorityMax={setSeniorityMax}
        onSubmit={onSubmit}
      />
    </>
  )
}

export const importJobsV2SeniorityColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => <SeniorityColumn data={data} onChange={onChange} />,
})

const HiringLocationsSelector = ({
  data,
  onChange,
}: {
  data: ImportInterface<ImportJobsInterface>
  onChange: GenericEditableTableOnChange
}) => {
  const { data: options, isLoading } = useGetSelectors<IdAndName>(selectorKeys.location)

  if (isLoading) {
    return <Skeleton />
  }

  if (!options?.length) {
    return <>No locations</>
  }

  const value =
    typeof data.data.locations === 'string'
      ? data.data.locations
          .split('; ')
          ?.map(location => {
            const locationObject = options.find(item => item.name === location)

            if (locationObject) {
              return {
                id: locationObject.id,
                name: locationObject.name,
              }
            }

            return null
          })
          ?.filter(Boolean) || []
      : []

  const label =
    typeof data.data.locations === 'string'
      ? data.data.locations.replaceAll(';', ',')
      : 'Select locations'

  const onHandleChange: GenericEditableTableOnChange = (rowId, val, field) => {
    if (Array.isArray(val)) {
      onChange(rowId, val.map(item => item.name).join('; '), field)
    }
  }

  return (
    <MultiSelectInputCell
      data={data}
      value={value}
      onChange={onHandleChange}
      field="locations"
      selector={selectorKeys.location}
      label={label}
    />
  )
}

export const importJobsV2LocationsColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'locations',
  dataPoint: 'locations',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Hiring locations',
  insert: ({ data }) => <HiringLocationsSelector data={data} onChange={onChange} />,
})

export const importJobsV2RecruiterColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'recruiter',
  dataPoint: 'recruiter',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Recruiter',
  insert: ({ data }) => (
    <EmployeeSelectCell data={data} onChange={onChange} field="recruiter" />
  ),
})

type ImportJobDescriptionSections = Pick<
  ImportJobsInterface,
  'about_the_role' | 'what_youll_be_doing' | 'what_youll_need' | 'nice_to_have'
>

const ChangeJobDescriptionPopup = ({
  data,
  onChange,
}: {
  data: ImportJobDescriptionSections
  onChange: (val: Partial<ImportJobDescriptionSections>) => void
}) => {
  const [open, setOpen] = useState(false)
  const [aboutTheRole, setAboutTheRole] = useState<string | undefined>(
    data.about_the_role,
  )
  const [whatYouWillBeDoing, setWhatYouWillBeDoing] = useState<string | undefined>(
    data.what_youll_be_doing,
  )
  const [whatYouWillNeed, setWhatYouWillNeed] = useState<string | undefined>(
    data.what_youll_need,
  )
  const [niceToHave, setNiceToHave] = useState<string | undefined>(data.nice_to_have)

  const clearEmptyInput = (cb: (val?: string) => void) => {
    return (val?: string) => {
      cb(val === '<p><br></p>' ? undefined : val)
    }
  }

  return (
    <>
      <Text
        onClick={() => setOpen(true)}
        style={{ cursor: 'pointer' }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(aboutTheRole || 'N/A', {
            ALLOWED_TAGS: [],
          }),
        }}
      />
      <Popup open={open} onClose={() => setOpen(false)} size="lg">
        <Header variant="item">
          <Header.Title>Change job description</Header.Title>
        </Header>
        <AutoStepper>
          <NewStepperTitle title="About the role" />
          <HTMLEditor
            placeholder="About the role"
            height={350}
            addMarginToParagraphs
            value={aboutTheRole}
            onChange={clearEmptyInput(setAboutTheRole)}
          />

          <NewStepperTitle title="What you’ll be doing" />
          <HTMLEditor
            placeholder="What you’ll be doing"
            height={350}
            addMarginToParagraphs
            value={whatYouWillBeDoing}
            onChange={clearEmptyInput(setWhatYouWillBeDoing)}
          />

          <NewStepperTitle title="What you'll need" />
          <HTMLEditor
            placeholder="What you'll need"
            height={350}
            addMarginToParagraphs
            value={whatYouWillNeed}
            onChange={clearEmptyInput(setWhatYouWillNeed)}
          />

          <NewStepperTitle title="Nice to have" />
          <HTMLEditor
            placeholder="Nice to have"
            height={350}
            addMarginToParagraphs
            value={niceToHave}
            onChange={clearEmptyInput(setNiceToHave)}
          />
        </AutoStepper>

        <Popup.Actions>
          <Button
            onClick={() => {
              onChange({
                about_the_role: aboutTheRole,
                what_youll_be_doing: whatYouWillBeDoing,
                what_youll_need: whatYouWillNeed,
                nice_to_have: niceToHave,
              })
              setOpen(false)
            }}
          >
            Save
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export const importJobsV2DescriptionColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'job_posting.id',
  dataPoint: 'job_posting.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Job description',
  insert: ({ data }) => (
    <CellWithError
      data={data}
      field={['about_the_role', 'what_youll_be_doing', 'what_youll_need', 'nice_to_have']}
    >
      <ChangeJobDescriptionPopup
        onChange={val => {
          onChange(data.id, val.about_the_role, 'about_the_role')
          onChange(data.id, val.what_youll_be_doing, 'what_youll_be_doing')
          onChange(data.id, val.what_youll_need, 'what_youll_need')
          onChange(data.id, val.nice_to_have, 'nice_to_have')
        }}
        data={data.data}
      />
    </CellWithError>
  ),
})

export const onboardingJobsV2TitleColumn: OnboardingJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: 'requisition_title',
  filterKey: 'id',
  selectorsKey: () => getRequisitionsSelectorOptions(),
  title: 'Job title',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="requisition_title" />
  ),
})

export const onboardingJobsV2RoleColumn: OnboardingJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="specialisation"
      selector={selectorKeys.specialisations}
      fieldName="role"
    />
  ),
})

export const onboardingJobsV2DescriptionColumn: OnboardingJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Job description',
  insert: ({ data }) => {
    return (
      <Box>
        <ChangeJobDescriptionPopup
          onChange={val => {
            const sections = data.data.sections
              ?.map(section => {
                switch (section.title) {
                  case JobDescriptionSections.About:
                    return { ...section, content: val.about_the_role }

                  case JobDescriptionSections.WhatYouWillBeDoing:
                    return { ...section, content: val.what_youll_be_doing }

                  case JobDescriptionSections.WhatYouWillNeed:
                    return { ...section, content: val.what_youll_need }

                  case JobDescriptionSections.NiceToHave:
                    return { ...section, content: val.nice_to_have }

                  default:
                    return null
                }
              })
              ?.filter(Boolean)

            onChange(data.id, sections, 'sections')
          }}
          data={(data.data.sections || []).reduce(
            (acc, section) => {
              switch (section.title) {
                case JobDescriptionSections.About:
                  acc.about_the_role = section.content
                  break

                case JobDescriptionSections.WhatYouWillBeDoing:
                  acc.what_youll_be_doing = section.content
                  break

                case JobDescriptionSections.WhatYouWillNeed:
                  acc.what_youll_need = section.content
                  break

                case JobDescriptionSections.NiceToHave:
                  acc.nice_to_have = section.content
                  break
              }

              return acc
            },
            {
              about_the_role: '',
              what_youll_need: '',
              what_youll_be_doing: '',
              nice_to_have: '',
            } as ImportJobDescriptionSections,
          )}
        />
      </Box>
    )
  },
})

export const onboardingJobsV2LocationsColumn: OnboardingJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'locations',
  dataPoint: 'locations',
  sortKey: null,
  filterKey: 'locations__id',
  selectorsKey: selectorKeys.location,
  title: 'Locations',
  insert: ({ data }) => (
    <MultiSelectInputCell
      data={data}
      onChange={onChange}
      field="locations"
      selector={selectorKeys.location}
    />
  ),
})
