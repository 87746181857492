import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'

import {
  CommonGenericEditableTableRowOptions,
  GenericEditableTable,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { TeamInterface } from '@src/interfaces/teams'
import {
  onboardingTeamsDepartmentColumn,
  onboardingTeamsHeadcountColumn,
  onboardingTeamsNameColumn,
  onboardingTeamsOwnerColumn,
} from '@src/constants/columns/onboardingTeams'
import { API } from '@src/constants/api'
import {
  CreateDepartmentPopup,
  CreateTeamPopup,
} from '@src/features/EditableEmployeesTable/components'
import { IdAndName } from '@src/interfaces'
import {
  BulkUpdateTeamPopup,
  DeleteTeamsConfirmationPopup,
  MergeTeamsPopup,
} from './components'

type CreateCallback = (
  teamId: number,
  onChangeAction: (entity: IdAndName) => void,
) => void

const row =
  (onCreate: CreateCallback) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<TeamInterface>> => ({
    cells: [
      {
        ...onboardingTeamsNameColumn(options.onChange),
        width: 200,
      },
      {
        ...onboardingTeamsDepartmentColumn(options.onChange, (id, onChangeAction) =>
          onCreate(id, onChangeAction),
        ),
        width: 200,
      },
      {
        ...onboardingTeamsHeadcountColumn(options.onChange),
        width: 100,
      },
      {
        ...onboardingTeamsOwnerColumn(options.onChange),
        width: 200,
      },
    ],
  })

type CreateNewDepartmentPopupState = {
  type: 'create-department'
  teamId: number
  onChangeAction: (entity: IdAndName) => void
}
type AddTeamPopupState = {
  type: 'add-team'
  refreshTable: () => void
}
type BulkUpdateFieldPopupState = {
  type: 'bulk-update-field'
  field?: 'department' | 'owner'
  items?: number[]
  refreshTable: () => void
}
type BulkDeletePopupState = {
  type: 'bulk-delete'
  items?: number[]
  refreshTable: () => void
}
type MergeTeamsPopupState = {
  type: 'merge-teams'
  items?: number[]
  refreshTable: () => void
}
type PopupState =
  | null
  | CreateNewDepartmentPopupState
  | AddTeamPopupState
  | BulkUpdateFieldPopupState
  | BulkDeletePopupState
  | MergeTeamsPopupState

export const ReviewTeams = () => {
  const [popupState, setPopupState] = useState<PopupState>(null)

  const onCreateNew = (teamId: number, onChangeAction: (entity: IdAndName) => void) => {
    setPopupState({ type: 'create-department', teamId, onChangeAction })
  }

  return (
    <>
      <GenericEditableTable
        apiEndpoint={API.TEAMS}
        tableName={TableNames.TeamsOnboarding}
        row={row(onCreateNew)}
        entity="team"
        variant="existingEntities"
        filterByInitial={[
          {
            columnName: 'status',
            filters: [{ id: 'active', name: 'active' }],
            nonResettable: true,
          },
        ]}
        sortByInitial={[{ sortBy: 'name', nonResettable: true }]}
        tableActions={props => {
          const selectedItems = props.getSelectedItems()
          const disableActions = selectedItems?.length < 1
          const disableMerge = selectedItems?.length < 2

          return (
            <MoreBar>
              <MoreBar.Action
                useIcon="Plus"
                onClick={() => {
                  setPopupState({
                    type: 'add-team',
                    refreshTable: props.refreshTableState,
                  })
                }}
              >
                Add team
              </MoreBar.Action>
              <MoreBar.Action
                useIcon="ArrowRightLeft"
                onClick={() => {
                  setPopupState({
                    type: 'bulk-update-field',
                    field: 'department',
                    items: props.getSelectedItems(),
                    refreshTable: props.refreshTableState,
                  })
                }}
                disabled={disableActions}
              >
                Change department
              </MoreBar.Action>
              <MoreBar.Action
                useIcon="Materials"
                onClick={() => {
                  setPopupState({
                    type: 'merge-teams',
                    items: props.getSelectedItems(),
                    refreshTable: props.refreshTableState,
                  })
                }}
                disabled={disableMerge}
              >
                Merge teams
              </MoreBar.Action>
              <MoreBar.Action
                useIcon="ArrowRightLeft"
                onClick={() => {
                  setPopupState({
                    type: 'bulk-update-field',
                    field: 'owner',
                    items: props.getSelectedItems(),
                    refreshTable: props.refreshTableState,
                  })
                }}
                disabled={disableActions}
              >
                Change owner
              </MoreBar.Action>
              <MoreBar.Action
                onClick={() => {
                  setPopupState({
                    type: 'bulk-delete',
                    items: props.getSelectedItems(),
                    refreshTable: props.refreshTableState,
                  })
                }}
                variant="negative"
                useIcon="Delete"
                disabled={disableActions}
              >
                Delete
              </MoreBar.Action>
            </MoreBar>
          )
        }}
        deleteConfirmation={props =>
          props.id ? (
            <DeleteTeamsConfirmationPopup
              open={props.open}
              onClose={props.onClose}
              teamsToDelete={[props.id]}
              onSuccess={props?.onSuccess}
            />
          ) : null
        }
      />

      {popupState?.type === 'add-team' && (
        <CreateTeamPopup
          open
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          onClose={() => setPopupState(null)}
          showDepartmentField
        />
      )}

      {popupState?.type === 'create-department' && (
        <CreateDepartmentPopup
          open
          onSuccess={department => {
            popupState.onChangeAction(department)
            setPopupState(null)
          }}
          onClose={() => setPopupState(null)}
        />
      )}

      {popupState?.type === 'merge-teams' && (
        <MergeTeamsPopup
          open
          onClose={() => setPopupState(null)}
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          unitsToMerge={popupState.items}
        />
      )}

      {popupState?.type === 'bulk-update-field' && (
        <BulkUpdateTeamPopup
          open
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          onClose={() => setPopupState(null)}
          entity={popupState.field}
          selectedTeams={popupState.items}
        />
      )}

      {popupState?.type === 'bulk-delete' && (
        <DeleteTeamsConfirmationPopup
          open
          onClose={() => setPopupState(null)}
          teamsToDelete={popupState.items}
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
        />
      )}
    </>
  )
}
