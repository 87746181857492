import React from 'react'
import useCandidatePipelineData from '@src/pages/Forms/JobPosting/Components/useCandidatePipelineData'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import CommonCandidatesTable from '@src/features/CommonCandidatesTable/CommonCandidatesTable'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { HiringPipelineInterface } from '@src/interfaces/hiringPipelines'

export const Candidates = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { hiringPipeline, loading } = useCandidatePipelineData(values.id)
  if (loading) {
    return <PageLoading />
  }
  return (
    <CommonCandidatesTable
      type="hiringPipeline"
      data={hiringPipeline as HiringPipelineInterface}
    />
  )
}
