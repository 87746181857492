import React from 'react'
import { FilterByInterface } from '@src/interfaces/data'
import { DataPointSkeleton, Highlights } from '@revolut/ui-kit'
import { useGetJobPostingStats } from '@src/api/jobPosting'
import {
  JobPostingStatCategory,
  JobPostingStatsInterface,
} from '@src/interfaces/jobPosting'
import { StatFilters } from '@src/components/StatFilters/StatFilters'

const STAT_FILTER_FIELDS = [
  'hiring_pipeline__recruiters',
  'hiring_pipeline__recruiters__team__team_owner',
  'hiring_pipeline__shared_with',
]

const getJobPostingStatsFilters = (filters?: FilterByInterface[]) => {
  const excludeStatsFilters = filters?.filter(
    item => !STAT_FILTER_FIELDS.includes(item.columnName),
  )
  return excludeStatsFilters
}

const getConfig = (stats: JobPostingStatsInterface[] = []) => {
  const config = stats.map(({ category, label, count }) => ({
    id: category,
    value: count,
    title: label,
  }))
  return config
}

const getCurrentStat = (
  filters: FilterByInterface[],
  stats: JobPostingStatsInterface[] = [],
): JobPostingStatCategory => {
  const currentStatFilter = filters.find(
    filter => STAT_FILTER_FIELDS.includes(filter.columnName) && filter.filters.length,
  )
  const currentStatCategory = stats.find(
    ({ filter_field_name }) => currentStatFilter?.columnName === filter_field_name,
  )
  return currentStatCategory?.category ?? 'Total'
}

type JobPostingStatsProps = {
  filters: FilterByInterface[]
  onFilterChange: (filter: FilterByInterface[]) => void
}

export const JobPostingStats = ({ filters, onFilterChange }: JobPostingStatsProps) => {
  const { data, isLoading: loadingPipelineStats } = useGetJobPostingStats(
    getJobPostingStatsFilters(filters),
  )
  if (loadingPipelineStats) {
    return (
      <Highlights>
        <DataPointSkeleton />
        <DataPointSkeleton />
        <DataPointSkeleton />
        <DataPointSkeleton />
      </Highlights>
    )
  }
  const stats = (data ?? [])?.filter(({ category }) => category !== 'access_pipelines')
  const config = getConfig(stats)
  const currentStat = getCurrentStat(filters, stats)
  const handleClick = (newStat: JobPostingStatCategory) => {
    if (newStat !== currentStat) {
      // there should only be 1 stat filter
      // this changes all stats filters so it only includes the current filter
      const newFilters = stats.map(stat => ({
        filters:
          newStat === stat.category
            ? stat.filters.map(id => ({ id, name: String(id) }))
            : [],
        columnName: stat.filter_field_name,
      }))
      onFilterChange(newFilters)
    }
  }
  return (
    <StatFilters<JobPostingStatCategory>
      filters={config}
      selectedFilter={currentStat}
      onClick={handleClick}
    />
  )
}
